<template>
   
    <button  :disabled=" filterTemplates.saving" v-if="filterTemplates && filterTemplates.filterGroup && filterTemplates.primKey && filterTemplates.isMine && filterTemplates.filterGroup.hasChanges" @click = "()=>filterTemplates.save()">
        {{$t('Save')}} 
        <div class="spinner-border spinner-border-sm me-1" role="status" v-if="filterTemplates.saving">
            <span class="sr-only"></span>
        </div>
    </button>
   
</template>
<script setup>
    const props =defineProps({
        filterTemplates:Object
    });
</script>